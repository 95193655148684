<template>
  <div class="action-box">
    <v-icon
      v-if="icon"
      color="secondaryLight"
      size="80"
    >
      {{ icon }}
    </v-icon>
    <h2 class="action-box__title">
      {{ title }}
    </h2>
    <p class="action-box__description">
      {{ description }}
    </p>

    <eewc-button-common
      :text="buttonLabel"
      @click="onActionButtonClick"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from "vue";

withDefaults(
  defineProps<{
    buttonLabel: string;
    title: string;
    description: string;
    icon: string;
  }>(),
  { buttonLabel: "", title: "", description: "", icon: "" }
);

const emits = defineEmits(["onRetry", "onUpload"]);

const onActionButtonClick = () => {
  emits("onRetry");
};
</script>

<style lang="scss" scoped>
@import "@/styles/public/main.scss";

.action-box {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 96px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;

  &__title {
    color: $primary;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
  }

  &__description {
    color: $secondary;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
  }
}
</style>
