// convert seconds into 00:00:00 format
const convertSecondsToTimeFormat = (seconds: number) =>
  new Date(seconds * 1000).toISOString().slice(11, 19);

const getConvertedJSONFromBufferArray = (bufferArray: []) => {
  const encoder = new TextDecoder("utf-8");
  const uint8Array = new Uint8Array(bufferArray);
  const encodedBufferArray = encoder.decode(uint8Array);

  return JSON.parse(encodedBufferArray);
};

export { convertSecondsToTimeFormat, getConvertedJSONFromBufferArray };
