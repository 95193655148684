import { CanvasDimensions, VideoDeviceSettings, VideoDewarper } from "@/types";
import EENDewarp from "../eenDewarp";

function useDewarp() {
  let videoDewarper: VideoDewarper | undefined = undefined;

  const dewarpVideo = (
    videoDeviceSettings: VideoDeviceSettings,
    videoCanvas: HTMLCanvasElement,
    videoPlayerContainer: HTMLDivElement,
    videoPlayer: HTMLVideoElement
  ) => {
    const config = { devices: [videoDeviceSettings] };

    const initVideoDewarp = (
      esn: string,
      dewarpVideoCanvas: HTMLCanvasElement,
      videoPlayerContainer: HTMLDivElement,
      videoElement: HTMLVideoElement
    ) => {
      if (videoElement?.crossOrigin) {
        videoElement.crossOrigin = "anonymous";
      }

      // eslint-disable-next-line
      const eenDewarp = EENDewarp as any;

      videoDewarper = eenDewarp.create_video_renderer({
        config: config,
        width: 1200,
        height: 1200,
        canvas: dewarpVideoCanvas,
        supersampling: 1.0,
      });
      videoDewarper?.initVideoSource(videoElement, esn, videoPlayerContainer);
      videoDewarper?.startPlayback(esn);
    };

    initVideoDewarp("esn", videoCanvas, videoPlayerContainer, videoPlayer);
  };

  const setResize = (canvasDimensions: CanvasDimensions) => {
    videoDewarper?.setRendererSize(
      canvasDimensions.width,
      canvasDimensions.height
    );
  };

  return { dewarpVideo, setResize };
}

export default useDewarp;
