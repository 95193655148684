import { VideoDeviceSettings } from "./types";

const BASE_PANE = {
  x: "0%",
  y: "0%",
  width: "100%",
  height: "100%",
  fov: 65,
  shader: "sphere",
};

const PANE_ONE = { ...BASE_PANE, lookAt: { x: 1, y: -1, z: 0 } };
const SINGLE_COMPOSITE = {
  id: "composite-id",
  aspect: 16 / 9.0,
  panes: [PANE_ONE],
};

const VIDEO_DEVICE_SETTINGS: VideoDeviceSettings = {
  esn: "esn",
  fov: 65,
  curve: "",
  mount: "",
  composites: [SINGLE_COMPOSITE],
};

const PLAYBACK_RATES = [
  {
    icon: "$icon_speed_1X",
    value: 1,
  },
  {
    icon: "$icon_speed_2X",
    value: 2,
  },
  {
    icon: "$icon_speed_4X",
    value: 4,
  },
  {
    icon: "$icon_speed_8X",
    value: 8,
  },
  {
    icon: "$icon_speed_zero_point_5X",
    value: 0.5,
  },
];

const ACCEPT_TYPE = "video/mp4";
const FILE_SIZE_UNITS = ["Bytes", "KB", "MB"];
const INITIAL_VIDEO_DURATION = 0;

export {
  ACCEPT_TYPE,
  FILE_SIZE_UNITS,
  INITIAL_VIDEO_DURATION,
  PLAYBACK_RATES,
  VIDEO_DEVICE_SETTINGS,
};
