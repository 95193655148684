import Vue from 'vue';
import vuetify from "@/plugins/vuetify";
import eewcComponents from '@eencloud/eewc-components/src';
import App from './App.vue';

Vue.use(eewcComponents)

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app');

