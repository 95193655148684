<template>
  <v-app>
    <div class="portrait-info-banner">
      <img
        class="portrait-info-banner__portrait-image"
        alt="portrait-mode"
        src="./assets/PortraitImage.png"
      >
      <p class="portrait-info-banner__info">
        {{ portraitInfoText }}
      </p>
    </div>
    <div class="app-wrapper">
      <TopNavbar v-if="!file || isErrorVideo" />
      <div
        v-if="!isErrorVideo"
        class="app-wrapper__action-wrapper"
      >
        <eewc-upload-file
          v-if="!file || videoProcessingOnLoading"
          :upload-text="uploadText"
          :upload-text-line2="uploadTextLine2"
          :upload-button-label="uploadButtonLabel"
          :accept-type="ACCEPT_TYPE"
          :file-size-units="FILE_SIZE_UNITS"
          :wrong-type-error-message="wrongTypeErrorMessage"
          :upload-file-error-message="uploadFileErrorMessage"
          @upload-done="onUploadSuccess"
        />
        <div
          v-if="file"
          ref="videoPlayerContainer"
          :class="
            videoProcessingOnLoading
              ? 'hide-video-player-container'
              : 'video-player-container'
          "
        >
          <Transition name="info-display">
            <div
              v-if="shouldShowInfo"
              class="video-player-container__info-card"
            >
              <v-icon
                size="66"
                color="primaryWhite"
              >
                $icon_ptz
              </v-icon>
              <p>{{ infoCardText }}</p>
            </div>
          </Transition>
          <div class="video-player-container__close">
            <v-icon
              :size="20"
              color="primaryWhite"
              @click="resetValues"
            >
              $icon_close
            </v-icon>
          </div>
          <video
            ref="videoPlayer"
            :class="[
              'video-player-container__video-player',
              !isFishEyeVideo && 'video-player-container__opacity-one',
            ]"
            playsinline
            disabled
            @ended="onVideoEnd"
          />
          <canvas
            v-if="isFishEyeVideo"
            ref="videoCanvas"
            class="video-player-container__video-canvas"
            @mouseover="onCanvasHover"
          />
        </div>
      </div>
      <ActionBox
        v-if="isErrorVideo"
        :button-label="errorButtonLabel"
        :title="errorTitle"
        :description="errorDescription"
        icon="$icon_disable"
        @onRetry="resetValues"
      />
      <div
        :class="file && !isErrorVideo && 'app-wrapper__media-control-wrapper'"
      >
        <MediaControlPanel
          :play-back-speed="
            videoPlayer?.playbackRate || defaultMediaPlaybackRate
          "
          :volume="videoPlayer?.volume"
          :media-current-time="mediaCurrentTime"
          :is-paused="isVideoPaused"
          :total-media-time="videoPlayer?.duration || INITIAL_VIDEO_DURATION"
          @onPlayControl="onVideoPlay"
          @onUpdateCurrentTime="onUpdateCurrentTime"
          @onUpdateVolume="handleVideoVolume"
          @onUpdateSpeed="handleVideoSpeed"
        />
      </div>
    </div>
  </v-app>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watchEffect } from "vue";
import mp4Box from "mp4box";

import ActionBox from "./components/ActionBox.vue";
import MediaControlPanel from "./components/MediaControlPanel.vue";
import TopNavbar from "./components/TopNavbar.vue";
import useDewarp from "./service/useDewarp";
import {
  ACCEPT_TYPE,
  FILE_SIZE_UNITS,
  INITIAL_VIDEO_DURATION,
  VIDEO_DEVICE_SETTINGS,
} from "./constants";
import { getConvertedJSONFromBufferArray } from "./utils";
import { FileData } from "./types";

interface FileBuffer extends ArrayBuffer {
  fileStart?: number;
}

const videoPlayer = ref<HTMLVideoElement>();
const videoPlayerContainer = ref<HTMLDivElement>();
const videoCanvas = ref<HTMLCanvasElement>();
const file = ref<FileData>();
const isVideoPaused = ref(true);
const isErrorVideo = ref(false);
const videoProcessingOnLoading = ref(false);
const mediaCurrentTime = ref(INITIAL_VIDEO_DURATION);
const shouldShowInfo = ref(false);
const isFishEyeVideo = ref(true);
const videoCurrentTimeUpdateInterval = ref();

const infoDisplayTimeoutMs = 2000;
const videoCurrentTimeUpdateDelayInMS = 100;
const defaultMediaPlaybackRate = 1;
const uploadText = "Drag and drop exported video from Eagle Eye camera";
const uploadTextLine2 = "or";
const uploadButtonLabel = "Browse videos";
const wrongTypeErrorMessage = "Upload failed. Supported file types are video.";
const uploadFileErrorMessage = "Upload failed. Please try again.";
const infoCardText = "Drag to rotate, scroll to zoom.";
const portraitInfoText = "Please use landscape mode";
const errorButtonLabel = "Try again";
const errorTitle = "The video cannot be played";
const errorDescription =
  "The current video does not originate from an Eagle Eye camera";

const { dewarpVideo, setResize } = useDewarp();

const onWindowResize = () => {
  if (videoCanvas.value) {
    setResize({
      width: window.innerWidth,
      // Calculate height to keep aspect ratio of the canvas is 16/9
      height: (window.innerWidth / 16) * 9,
    });
  }
};

onMounted(() => window.addEventListener("resize", onWindowResize));

onUnmounted(() => window.removeEventListener("resize", onWindowResize));

watchEffect(() => {
  if (isVideoPaused.value) {
    clearInterval(videoCurrentTimeUpdateInterval.value);
    return;
  }

  videoCurrentTimeUpdateInterval.value = setInterval(() => {
    if (!videoPlayer.value) {
      return;
    }

    mediaCurrentTime.value = videoPlayer.value.currentTime;
  }, videoCurrentTimeUpdateDelayInMS);

});

const processVideoFile = async (file: File) => {
  if (file) {
    const mp4BoxFile = mp4Box.createFile(false);

    const fileBuffer: FileBuffer = await file.arrayBuffer();
    fileBuffer.fileStart = 0;
    mp4BoxFile.appendBuffer(fileBuffer);
    mp4BoxFile.onError = console.error;

    const udtaData = mp4BoxFile.udta;
    videoPlayer.value?.setAttribute("src", URL.createObjectURL(file));
    videoProcessingOnLoading.value = false;
    if (!udtaData?.lens?.data || !udtaData?.dcon?.data) {
      isFishEyeVideo.value = false;

      return;
    }

    try {
      const convertedLens = getConvertedJSONFromBufferArray(udtaData.lens.data);
      const convertedDcon = getConvertedJSONFromBufferArray(udtaData.dcon.data);

      const updatedVideoDeviceSettings = {
        ...VIDEO_DEVICE_SETTINGS,
        fov: convertedLens.fov,
        mount: convertedDcon.mount,
      };

      if (
        videoCanvas.value &&
        videoPlayer.value &&
        videoPlayerContainer.value
      ) {
        dewarpVideo(
          updatedVideoDeviceSettings,
          videoCanvas.value,
          videoPlayerContainer.value,
          videoPlayer.value
        );
      }
    } catch (error) {
      isErrorVideo.value = true;
    }
  }
};

const onUploadSuccess = (mediaData: FileData) => {
  videoProcessingOnLoading.value = true;
  processVideoFile(mediaData.addedFile);
  file.value = mediaData;
};

const onVideoPlay = () => {
  if (videoPlayer.value?.paused) {
    videoPlayer.value.play();
    isVideoPaused.value = false;

    return;
  }

  if (videoPlayer.value?.play) {
    videoPlayer.value?.pause();
    isVideoPaused.value = true;
  }
};

const onUpdateCurrentTime = (
  time = INITIAL_VIDEO_DURATION,
  isIncrement = true
) => {
  if (videoPlayer.value) {
    const updatedTime = isIncrement
      ? videoPlayer.value.currentTime + time
      : time;
    videoPlayer.value.currentTime = updatedTime;
  }
};

const resetValues = () => {
  isVideoPaused.value = true;
  isErrorVideo.value = false;
  videoPlayer.value?.removeAttribute("src");
  file.value = undefined;
  isFishEyeVideo.value = true;
  mediaCurrentTime.value = INITIAL_VIDEO_DURATION;
};

const handleVideoVolume = (volume: number) => {
  if (videoPlayer.value) {
    videoPlayer.value.volume = volume;
  }
};

const handleVideoSpeed = (speed: number) => {
  if (videoPlayer.value) {
    videoPlayer.value.playbackRate = speed;
  }
};

const onCanvasHover = () => {
  shouldShowInfo.value = true;

  setTimeout(() => {
    shouldShowInfo.value = false;
  }, infoDisplayTimeoutMs);
};

const onVideoEnd = () => {
  isVideoPaused.value = true;
  isErrorVideo.value = false;
  mediaCurrentTime.value = INITIAL_VIDEO_DURATION;
  if (videoPlayer.value) {
    videoPlayer.value.currentTime = INITIAL_VIDEO_DURATION;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/public/main.scss";

.app-wrapper {
  height: 100vh;
  background-color: $primaryBackgrounds;
  display: flex;
  flex-direction: column;
  position: relative;

  &__action-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 100vh;
  }

  &__media-control-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
}

.video-player-container {
  position: relative;
  max-width: 100vw;
  width: 100%;
  max-height: 100vh;
  aspect-ratio: 16/9;
  overflow: hidden;

  &__video-player {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__opacity-one {
    opacity: 1 !important;
  }

  &__video-canvas {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &__info-card {
    background: $primaryFaded;
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 2;
    display: flex;
    color: $primaryWhite;
    padding: 15px 24px;
    width: 324px;
    height: 94px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    p {
      margin-bottom: unset !important;
    }
  }

  &__close {
    border-radius: 4px;
    background: $primaryFadedMedium;
    backdrop-filter: blur(1.995833396911621px);
    position: absolute;
    right: 3%;
    top: 3%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 4px 4px 8px;
    width: 36px;
    height: 36px;
  }
}

.hide-video-player-container {
  width: 0%;
  overflow: hidden;
}

.info-display-enter-active,
.info-display-leave-active {
  transition: opacity 0.5s;
}

.info-display-enter,
.info-display-leave-to {
  opacity: 0;
}

:deep .drop-area > label {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 32px 48px;
}

:deep .drop-area > label > button {
  width: 40%;
  min-width: 108px;
}

:deep .drop-area > label > span {
  color: $secondary;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 18rem;
}

:deep .drop-area > label > span:nth-child(2) {
  font-weight: 500;
}

:deep .drop-area > label > button {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.portrait-info-banner {
  display: none;
}

@media screen and (orientation: landscape) and (max-width: 915px) {
  .video-player-container {
    height: 100%;
    width: auto;
  }

  :deep .drop-area {
    height: 50vh !important;
    padding: 0 !important;
  }

  :deep .drop-area > label {
    padding: 5vh !important;
  }

  .app-wrapper {
    height: 100vh;
    overflow: hidden;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px) {
  .app-wrapper {
    display: none;
  }

  .portrait-info-banner {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__portrait-image {
      height: 64px;
      width: 64px;
      margin-bottom: 1.8rem;
    }

    &__info {
      color: $secondary;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }
}
</style>
