<template>
  <div class="control-panel">
    <div class="control-panel__video-progress-wrapper">
      <eewc-slider-simple
        :value="currentTimePercent"
        :min="minimumMediaDurationPercentage"
        :max="maximumMediaDurationPercentage"
        :step="0.01"
        @start="onScrollStart"
        @end="onScrollEnd"
      />
    </div>
    <div class="control-panel__controls-wrapper">
      <!-- left section -->
      <div
        class="controls-container minimum-container-width justify-content-start"
      >
        <v-icon
          size="30"
          color="primaryWhite"
          @click="onUpdateSpeed"
        >
          {{ PLAYBACK_RATES[currentSpeedIndex]?.icon }}
        </v-icon>
        <div class="controls-container__seek-control-container">
          <v-icon
            size="30"
            color="primaryWhite"
            @click="onBackward"
          >
            $icon_10_sec_before 
          </v-icon>
          <v-icon
            size="30"
            color="primaryWhite"
            @click="onForward"
          >
            $icon_10_sec_after
          </v-icon>
        </div>
        <p class="controls-container__time-display-text">
          {{ convertSecondsToTimeFormat(currentTime) }} /
          {{ convertSecondsToTimeFormat(totalMediaTime) }}
        </p>
      </div>
      <!-- middle part -->
      <div class="controls-container full-width">
        <v-icon
          :size="controlIconSize"
          color="primaryWhite"
          @click="onBackward"
        >
          $icon_previous
        </v-icon>
        <v-icon
          v-show="isMediaPaused"
          :size="controlIconSize"
          color="primaryWhite"
          @click="togglePlayAndPause"
        >
          $icon_play
        </v-icon>
        <v-icon
          v-show="!isMediaPaused"
          :size="controlIconSize"
          color="primaryWhite"
          @click="togglePlayAndPause"
        >
          $icon_pause
        </v-icon>
        <v-icon
          :size="controlIconSize"
          color="primaryWhite"
          @click="onForward"
        >
          $icon_next
        </v-icon>
      </div>
      <!-- right section -->
      <div class="volume-panel minimum-container-width">
        <div class="volume-panel__volume-container">
          <v-icon
            :size="controlIconSize"
            color="primaryWhite"
          >
            $icon_sound
          </v-icon>
          <eewc-slider-simple
            v-model="mediaVolume"
            :min="mediaMinimumVolume"
            :max="mediaMaximumVolume"
            :step="mediaVolumeJumpStep"
            @input="updateVolumeOnSeek"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watchEffect, withDefaults } from "vue";
import { INITIAL_VIDEO_DURATION, PLAYBACK_RATES } from "../constants";
import { convertSecondsToTimeFormat } from "../utils";

const jumpDuration = 10;
const mediaMinimumVolume = 0;
const mediaMaximumVolume = 1;
const mediaVolumeJumpStep = 0.01;
const minimumMediaDurationPercentage = 0;
const maximumMediaDurationPercentage = 100;
const controlIconSize = 32;

const props = withDefaults(
  defineProps<{
    isPaused: boolean;
    mediaCurrentTime: number;
    totalMediaTime?: number;
    volume?: number;
    playBackSpeed: number;
  }>(),
  {
    mediaCurrentTime: INITIAL_VIDEO_DURATION,
    volume: 0,
    totalMediaTime: INITIAL_VIDEO_DURATION,
  }
);

const emits = defineEmits<{
  (e: "onPlayControl"): void;
  (e: "onUpdateCurrentTime", value?: number, isTimeIncrement?: boolean): void;
  (e: "onUpdateVolume", value: number): void;
  (e: "onUpdateSpeed", value: number): void;
}>();

const currentTime = ref();
const isMediaPaused = ref();
const mediaVolume = ref(mediaMinimumVolume);
const totalMediaTime = ref(INITIAL_VIDEO_DURATION);
const currentSpeedIndex = ref();
const currentTimePercent = ref(minimumMediaDurationPercentage);
const sliderAnimationID = ref(minimumMediaDurationPercentage);

const updateTime = () => {
  currentTimePercent.value =
    (props.mediaCurrentTime / props.totalMediaTime) * 100 ||
    minimumMediaDurationPercentage;
  sliderAnimationID.value = requestAnimationFrame(updateTime);
}

watchEffect(() => {
  currentTime.value = props.mediaCurrentTime;
  isMediaPaused.value = props.isPaused;
  mediaVolume.value = props.volume;
  totalMediaTime.value = props.totalMediaTime;
  currentSpeedIndex.value = PLAYBACK_RATES.findIndex(
    (speed) => speed.value === props.playBackSpeed
  );

  if (isMediaPaused.value) {
    currentTimePercent.value =
      (props.mediaCurrentTime / props.totalMediaTime) * 100 ||
      minimumMediaDurationPercentage;
    cancelAnimationFrame(sliderAnimationID.value);
  } else {
    sliderAnimationID.value = requestAnimationFrame(updateTime);
  }
});

const togglePlayAndPause = () => {
  emits("onPlayControl");
};

const onForward = () => {
  emits("onUpdateCurrentTime", jumpDuration);
};

const onBackward = () => {
  emits("onUpdateCurrentTime", -jumpDuration);
};

const onUpdateSpeed = () => {
  if (currentSpeedIndex.value < PLAYBACK_RATES.length - 1) {
    currentSpeedIndex.value += 1;
  } else {
    currentSpeedIndex.value = 0;
  }
  emits("onUpdateSpeed", PLAYBACK_RATES[currentSpeedIndex.value].value);
};

const onScrollEnd = async(timePercent: number) => {
  const updatedTime = props.totalMediaTime * timePercent / 100;
  emits("onUpdateCurrentTime", updatedTime, false);
  emits("onPlayControl");
}

const onScrollStart = () => {
  emits("onPlayControl");
}

const updateVolumeOnSeek = (volume: number) => {
  emits("onUpdateVolume", volume);
};
</script>

<style lang="scss" scoped>
@import "@/styles/public/main.scss";

.control-panel {
  width: 100%;
  height: 112px;
  background: $primaryMedium;
  display: flex;
  flex-direction: column;
  padding: 22px;
  justify-content: center;

  &__video-progress-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__controls-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }
}

.controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  &__seek-control-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__time-display-text {
    color: $primaryWhite;
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.justify-content-start {
  justify-content: flex-start !important;
}

.minimum-container-width {
  width: 40%;
}

.full-width {
  width: 100%;
}

.volume-panel {
  display: flex;
  justify-content: end;
  align-items: center;

  &__volume-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
  }
}

:deep .theme--light.v-icon {
  color: unset !important;
}

:deep .theme--light.v-messages {
  display: none;
}

:deep .slider .v-slider__track-container .v-slider__track-background {
  border-radius: 0%;
}

:deep
  .control-panel__video-progress-wrapper
  .slider
  .v-slider__track-container
  .v-slider__track-fill {
  background-color: $accent !important;
  cursor: pointer;
  border-radius: 0%;
  height: 4px;
}

:deep .control-panel__video-progress-wrapper .slider .v-slider__thumb {
  background-color: $accent !important;
  box-shadow: none !important;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
}

:deep .v-slider--horizontal {
  margin-right: 0;
}

:deep
  .control-panel__video-progress-wrapper
  .slider
  .v-slider__track-container {
  height: 4px !important;
}

:deep
  .control-panel__video-progress-wrapper
  .slider
  .v-slider__track-container
  .v-slider__track-background {
  background-color: $secondaryMedium !important;
  cursor: pointer;
  height: 4px;
}

:deep .volume-panel__volume-container .slider .v-slider__track-container {
  height: 3px !important;
}

:deep
  .volume-panel__volume-container
  .slider
  .v-slider__track-container
  .v-slider__track-fill {
  background-color: $primaryWhite !important;
  border-radius: 0%;
  height: 3px;
}

:deep .volume-panel__volume-container .slider .v-slider__thumb {
  display: none;
}

:deep .volume-panel__volume-container .v-input__slot {
  margin-bottom: 0;
}

:deep
  .volume-panel__volume-container
  .slider
  .v-slider__track-container
  .v-slider__track-background {
  height: 3px;
  background-color: $secondaryMedium !important;
}

@media screen and (orientation: landscape) and (max-width: 915px) {
  .minimum-container-width {
    width: 100%;
  }

  .control-panel {
    height: 80px;
  }

  :deep .v-slider--horizontal {
    min-height: 5px !important;
  }

  .volume-panel__volume-container{
    align-items: center;
  }
}
</style>
