import { render, staticRenderFns } from "./ActionBox.vue?vue&type=template&id=02a60b47&scoped=true"
import script from "./ActionBox.vue?vue&type=script&setup=true&lang=ts"
export * from "./ActionBox.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ActionBox.vue?vue&type=style&index=0&id=02a60b47&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a60b47",
  null
  
)

export default component.exports